:root {
    --bg-brown: #68080A;
    --bg-gray: #B4B4B4;
    --bg-light-gray: #CDCDCF;
    --bg-red: #D2152B;
    --bg-light-blue: #25AAE1;
    --text-secondary-color: #FFDE16;
}

.bg-brown {
    background: var(--bg-brown);
}

.bg-gray {
    background: var(--bg-gray);
}

.bg-ligt-blue {
    background: var(--bg-light-blue);
}

.bg-light-gray {
    background: var(--bg-light-gray);
}

.bg-red {
    background: var(--bg-red);
}

.text-show {
    padding: 27px;
    font-size: 32px;
    font-weight: 700;
}

.p-4 {
    padding: 4px;
}

.ion-d-flex {
    display: flex;
}

.w-full {
    width: 100%;
}

.w-100 {
    width: 100px;
}

/* Define the animation */
@keyframes ticker {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.change-loc-id{
    display: inline-block;
}

/* Apply the animation to the text */
.news-ticker {
    overflow: hidden;
    /* Hide any overflow */
    white-space: nowrap;
    /* Prevent line breaks */
    animation: ticker 20s linear infinite;
    /* Apply the animation */
}

.heading-medium {
    font-size: 25px;
    font-weight: 700;
    padding: 13px;
}

.text-white {
    color: white;
}

.text-secondary {
    color: var(--text-secondary-color);
}

.p-medium {
    font-size: 16px;
    font-weight: 700;
    padding-left: 22px;
}

.overflow-hidden {
    overflow: hidden;
}

.header-wrapper {
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.flag-box {
    display: flex;
    gap: 6px;
    justify-content: flex-end;
}

ion-modal#example-modal {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }
  
  ion-modal#example-modal h1 p {
    margin: 20px 20px 10px 20px;
  }
  
  ion-modal#example-modal ion-icon {
    margin-right: 6px;
  
    width: 48px;
    height: 48px;
  
    padding: 4px 0;
  
    color: #aaaaaa;
  }
  
  ion-modal#example-modal .wrapper {
    padding: 35px;
  }

  .blinker{
    background-color: #cf0a2c;
	animation: blinkingBackground 2.5s infinite;
}

@keyframes blinkingBackground{
    0%		{ background-color: #f3c1ca;}
    25%		{ background-color: #fff;}
    50%		{ background-color: #f3c1ca;}
    75%		{ background-color: #fff;}
    100%	{ background-color: #f3c1ca;}
}

.box-blinker{
    background-color: #CDCDCF;
	animation: blinkingTicketBoxBackground 4s;
}

@keyframes blinkingTicketBoxBackground{
    0%		{ background-color: #68080A; color: #ffffff !important;}
    25%		{ background-color: #CDCDCF;}
    50%		{ background-color: #68080A; color: #ffffff !important;}
    75%		{ background-color: #CDCDCF;}
    100%	{ background-color: #68080A; color: #ffffff !important;}
}

.box-blinker h2{
	animation: blinkingTicketBoxColor 4s;
    /* color: #fff !important; */
}

@keyframes blinkingTicketBoxColor{
    0%		{ color: #FFFFFF;}
    25%		{ color: #000000;}
    50%		{ color: #FFFFFF;}
    75%		{ color: #000000;}
    100%	{ color: #FFFFFF;}
}